import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AzureConfig } from "../MSAL_Library";
import { VenueConfig } from "../Types";
import { RootState } from "./store";

export interface GlobalState {
    azureConfig: AzureConfig;
    userRoles: string[];
    accessToken: string;
    venues: VenueConfig[];
}

const initialState: GlobalState = {
    azureConfig: null,
    userRoles: [],
    accessToken: "",
    venues: []
};

export const globalStateSlice = createSlice({
    name: "globalStateSlice",
    initialState: initialState,
    reducers: {
        updateAzure: (state, action: PayloadAction<AzureConfig>) => { state.azureConfig =  action.payload},
        updateRoles: (state, action: PayloadAction<string[]>) => { state.userRoles = action.payload},
        updateAccessToken: (state, action: PayloadAction<string>) => {state.accessToken = action.payload},
        updateVenues: (state, action: PayloadAction<VenueConfig[]>) => {state.venues = action.payload}
    }
});

export const { updateAzure, updateRoles, updateAccessToken, updateVenues } = globalStateSlice.actions;

export const getAzureConfig = (state: RootState) => state.global.azureConfig;

export const getAppScope = (state: RootState) => state.global.azureConfig.Scope;

export const getRoles = (state: RootState) => state.global.userRoles;

export const getAccessToken = (state: RootState) => state.global.accessToken;

export const getVenues = (state: RootState) => state.global.venues;

export default globalStateSlice.reducer;