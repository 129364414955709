import * as React from "react";
import { Container, Row } from "reactstrap";

export const LoginPage:React.FC = () => {

    return (<Container>
        <br />
        <br />
        <Row  style={{textAlign: "center", display: "block"}}>
            <h2>Welcome to the Flight Club Darts Internal Tools</h2>
        </Row>
        <br />
        <br />
        <Row>
            Please login to access this page.
        </Row>
        <br />
        <Row>
            If you feel you should have access to this site but don't currently please contact either your team leader or Red Engine contact to have this granted.
        </Row>
    </Container>);
}