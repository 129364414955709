import * as React from "react";
import { Spinner } from "reactstrap";
import { LoginPage } from "./Components/LoginPage";
import { RouterComponent } from "./Components/RouterComponent";
import { AzureConfig, MSALAuthLibrary } from "./MSAL_Library";
import { useAppDispatch, useAppSelector } from "./Redux/hooks";
import { getAzureConfig, updateAzure } from "./Redux/techHubSlice";

const App: React.FC = () => {
  const azureConfig = useAppSelector(getAzureConfig);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    var client = new XMLHttpRequest();
    client.onreadystatechange = function () {
      if (client.readyState === client.DONE && client.status === 200) {
        const config: AzureConfig = JSON.parse(this.responseText);
        dispatch(updateAzure(config));
      }
    };
    client.open("GET", "api/Configuration/GetAzureConfig", true);
    client.send();
  }, []);

  if (azureConfig === null) {
    return (
      <div>
        <Spinner color="dark" />
        <div>Loading...</div>
      </div>
    );
  }

  return (
      <div className="App">
        <MSALAuthLibrary
          azureConfig={azureConfig}
          siteName={"FC Internal Applications"}
          loginPageComponet={LoginPage}
        >
          <RouterComponent />
        </MSALAuthLibrary>
      </div>
  );
};

export default App;
