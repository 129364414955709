import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import * as React from "react";

export const MainContent: React.FC<{loginPageComponet: React.ReactNode}> = (props) => {    

    return (
        <div>
            <AuthenticatedTemplate>
                {props.children}
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                {props.loginPageComponet}
            </UnauthenticatedTemplate>
        </div>
    );
  };