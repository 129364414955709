import { Configuration, PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import * as React from "react";
import { AzureConfig, MainContent, PageLayout } from ".";

export const MSALAuthLibrary: React.FC<{ azureConfig: AzureConfig, siteName: string, loginPageComponet: React.ReactNode }> = (props) => {
  const msalConfig: Configuration = {
    auth: {
        clientId: props.azureConfig.ClientId,
        authority: props.azureConfig.Instance + props.azureConfig.TenantId,
    },
    cache:{
      cacheLocation: "localStorage"
    }
  };

  const msalInstance = new PublicClientApplication(msalConfig);

    return (<div>
      <MsalProvider instance={msalInstance}>
        <PageLayout siteName={props.siteName} config={props.azureConfig}>
          <MainContent loginPageComponet={props.loginPageComponet}>
            {props.children}
          </MainContent>
        </PageLayout>
      </MsalProvider>
    </div>);
}