import React from "react";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useAccount, useMsal } from "@azure/msal-react";
import { Navbar  } from "reactstrap";
import { Button } from "react-rainbow-components";
import { AzureConfig } from "./AzureConfig";
import { PopupRequest } from "@azure/msal-browser";

export const PageLayout: React.FC<{siteName : string, config : AzureConfig}> = (props) => {
  return (
      <>
        <Navbar bg="primary" variant="dark">
          <a href="/">
            <h3>
            {props.siteName}
          </h3>
          </a>
          <SignInSignOutButton config={props.config}/>
        </Navbar>
        <hr style={{margin: 0}} />
        {props.children}
      </>
  );
};

const SignInSignOutButton: React.FC<{config :AzureConfig}> = (props) => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const loginRequest: PopupRequest = {
    scopes: [ "User.Read", props.config.Scope ]
    };
  const logoutRequest ={ account: account, postLogoutRedirectUri: "/" };

  React.useEffect(() => {
  }, [accounts]);

  return (
    <>
      <AuthenticatedTemplate>
      <div style={{textAlign: "right", display: "block", fontWeight: "bold", position: "absolute", right: "120px"}}>{account ? account.name : null}</div>
        <Button className="buttonStyle" onClick={() => instance.logout(logoutRequest)} label="Sign Out" style={{color: "black"}} />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Button onClick={() => instance.loginRedirect(loginRequest)} className="buttonStyle" label="Sign In" style={{color: "black"}} />
      </UnauthenticatedTemplate>
    </>
  );
};